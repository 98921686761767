import React from 'react';
import { useTranslation } from 'react-i18next';
import SocialRow from '../SocialRow/SocialRow';
import './Navbar.scss';
import { useLocation } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';

const TopNavBar = () => {
    const location = useLocation();
    const { t } = useTranslation();

    const isPathActive = (path) => {
        if (location.pathname === path) {
            return true;
        }

        return false;
    };

    return (
        <div className="navigation fixed-top">
            <Navbar expand="lg">
                <a href="/" className="logo">
                    Veronika Kubošová
                </a>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <ul className="navbar-nav ml-auto mr-4">
                        <li className="nav-item">
                            <a
                                href="/"
                                className={
                                    isPathActive('/')
                                        ? 'active nav-link'
                                        : 'nav-link'
                                }
                            >
                                {t('Navigation.Home')}
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                href="/ilustrace"
                                className={
                                    isPathActive('/ilustrace')
                                        ? 'active nav-link'
                                        : 'nav-link'
                                }
                            >
                                {t('Navigation.Illustrations')}
                            </a>
                        </li>
                        {/* <li className="nav-item">
                            <a
                                href="/grafika"
                                className={
                                    isPathActive('/grafika')
                                        ? 'active nav-link'
                                        : 'nav-link'
                                }
                            >
                                Grafika
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                href="/malba"
                                className={
                                    isPathActive('/malba')
                                        ? 'active nav-link'
                                        : 'nav-link'
                                }
                            >
                                Malba
                            </a>
                        </li> */}
                        {/* <li className="nav-item">
                            <a
                                className={(navData) => (navData.isActive ? "active " : "") + "nav-link"}
                                
                                href="/fotografie"
                            >
                                Fotografie
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className={(navData) => (navData.isActive ? "active " : "") + "nav-link"}
                                
                                href="/ostatni"
                            >
                                Ostatní
                            </a>
                        </li> */}
                    </ul>
                    <SocialRow />
                </Navbar.Collapse>
            </Navbar>
        </div>
    );
};

export default TopNavBar;
