import React from 'react';
import ImageCardList from '../../components/ImageCardList/ImageCardList';
import { illustrations } from '../../assets/illustrations/illustrations';

const Illustrations = () => {
    return (
        <div className="main">
            <ImageCardList images={illustrations} />
        </div>
    );
};

export default Illustrations;
