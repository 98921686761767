import React from 'react';
import './Header.scss';

const Header = () => {
    return (
        <div className="header">
            <h1>PORTFOLIO</h1>
            {/* <h2>Veronika Kubošová</h2> */}
            <hr />
        </div>
    );
};

export default Header;
