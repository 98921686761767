import React, { Fragment } from 'react';
import ImageCard from '../ImageCard/ImageCard';
import './ImageCardList.scss';

const ImageCardList = ({ images }) => {
    return (
        <div className="gallery text-center">
            {images.map((image, index) => (
                <Fragment key={`fragment${index}`}>
                    <ImageCard card={image} key={index} />
                </Fragment>
            ))}
        </div>
    );
};

export default ImageCardList;
