import React from 'react';
import './ImageCard.scss';
import { Modal } from 'react-bootstrap';

const ImageCard = ({ card }) => {
    // const [show, setShow] = useState(false);

    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);

    return (
        <>
            <div
                className="card"
                // onClick={handleShow}
            >
                <img
                    src={card.src}
                    alt={card.alt}
                    className="card-img-top thumbnail"
                    style={{ width: card.width ? card.width : 'auto' }}
                />
            </div>

            <Modal
                // show={show}
                // onHide={handleClose}
                backdrop="false"
                dialogClassName="rmodal"
                contentClassName="rmodal-content"
            >
                {/* <Modal.Header closeButton className="rmodal-header" /> */}

                <Modal.Body className="rmodal-body">
                    <img src={card.src} alt={card.alt} />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ImageCard;
