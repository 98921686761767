import React from 'react';
import ImageCardList from '../../components/ImageCardList/ImageCardList';
import Header from '../../components/Header/Header';
import InfoPanel from '../../components/InfoPanel/InfoPanel';
import { landingIllustrations } from '../../assets/landing/landing';

const Home = () => {
    return (
        <div className="main">
            <Header />
            <InfoPanel />
            <ImageCardList images={landingIllustrations} />
        </div>
    );
};

export default Home;
