import React, { useState } from 'react';
import './Sidebar.scss';
import Instagram from '../../assets/icons/instagram.svg';
import Envelope from '../../assets/icons/envelope.svg';
import { Modal } from 'react-bootstrap';
import { INSTAGRAM_LINK } from '../../constants';

const Sidebar = () => {
    const [show, setShow] = useState(false);
    const toggleModal = () => setShow((prev) => !prev);

    return (
        <>
            <div className="sidebar">
                <ul className="social">
                    <li>
                        <a
                            href={INSTAGRAM_LINK}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={Instagram} alt="instagram" />
                        </a>
                    </li>

                    <li>
                        <span onClick={toggleModal}>
                            <img src={Envelope} alt="kontakt" />
                        </span>
                    </li>
                </ul>
            </div>

            <Modal show={show} onHide={toggleModal} backdrop="false">
                <Modal.Header closeButton />

                <Modal.Body>
                    <div className="sidebar-modal-body">
                        <h3>Veronika Kubošová (Bašusová)</h3>
                        <p>
                            <strong>E-mail:</strong>{' '}
                            <a href="mailto:ve.kubosova@gmail.com">
                                ve.kubosova@gmail.com
                            </a>
                        </p>
                        <p>
                            <strong>Instagram:</strong>{' '}
                            <a
                                href={INSTAGRAM_LINK}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Veronika
                                <img
                                    src={Instagram}
                                    alt="instagram"
                                    style={{ width: '50px' }}
                                />
                            </a>
                        </p>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Sidebar;
