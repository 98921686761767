import React from 'react';
import SocialRow from '../SocialRow/SocialRow';

const Footer = () => {
    const date = new Date();
    const currentYear = date.getFullYear();

    return (
        <footer className="footer pv3 ph3 ph5-m ph6-l mid-gray">
            <small className="f6 db tc">
                © {currentYear} <b className="ttu">Veronika Kubošová</b>
            </small>
            <span className="tc">
                <SocialRow />
            </span>
        </footer>
    );
};

export default Footer;
