import {
  ILLUSTRATIONS,
  modalExpandedWidth,
} from "../../helpers/imageConstants";

export const landingIllustrations = [
  {
    id: "landing-0",
    src: ILLUSTRATIONS.zajicBalon,
    width: modalExpandedWidth,
  },

  {
    id: "landing-2",
    src: ILLUSTRATIONS.velryby,
    width: modalExpandedWidth,
  },
  {
    id: "landing-3",
    src: ILLUSTRATIONS.zelva,
    width: modalExpandedWidth,
  },
];
